<template>
    <div>
        <el-form :inline="true">
            <el-form-item label="订单编号" label-width="100">
                <el-input v-model="data.billCode" placeholder="订单编号" clearable>
                </el-input>
            </el-form-item>
            <el-form-item label="货物名称" label-width="100">
                <el-input v-model="data.goodsName" placeholder="货物名称" clearable>
                </el-input>
            </el-form-item>
            <el-form-item label="地址" label-width="100">
                <el-input v-model="data.address" placeholder="地址" clearable>
                </el-input>
            </el-form-item>
            <el-form-item label="订单状态" label-width="100">
                <el-select v-model="data.billState" clearable placeholder="订单状态">
                    <el-option v-for="(item, index) in billStateList" :key="index" :label="item.billStateName"
                        :value="item.billState"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button @click="data.pageNo = 1; getgetBillPage();" type="success">搜索</el-button>
                <el-button type="primary" @click="exportSurfList()">导出</el-button>
            </el-form-item>
        </el-form>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column prop="billId" align="center" width="100" label="订单id">
            </el-table-column>
            <el-table-column prop="billCode" align="center" width="200" label="订单编码">
            </el-table-column>
            <el-table-column prop="billStateStr" align="center" width="120" label="订单状态">
                <!-- <template slot-scope="row">
                    <span v-for="(item, index) in billStateList" :key="index" v-if="item.billState==row.billState">{{ item.billStateName }}</span>
                </template> -->
            </el-table-column>
            <el-table-column prop="startingPoint" align="center" width="200" label="起始点">
            </el-table-column>
            <el-table-column prop="endPoint" align="center" width="200" label="终止点">
            </el-table-column>
            <el-table-column prop="goods" align="center" min-width="300" label="货物">
            </el-table-column>
            <el-table-column prop="vehicleType" align="center" width="160" label="车辆类型">
            </el-table-column>
            <el-table-column prop="truckCount" align="center" width="100" label="货车数量">
            </el-table-column>
            <el-table-column prop="estimatedMileage" align="center" width="140" label="行驶里程（km）">
            </el-table-column>
            <el-table-column prop="loadingTime" align="center" width="200" label="装货时间">
            </el-table-column>

            <el-table-column prop="finalFreight" align="center" width="100" label="运费（元）">
            </el-table-column>
            <el-table-column prop="transactionMethod" align="center" width="120" label="成交方式">
                <template slot-scope="{row}">
                    <span v-if='row.transactionMethod == 1'>一口价</span>
                    <span v-if='row.transactionMethod == 2'>货主定价</span>
                    <span v-if='row.transactionMethod == 3'>议价</span>
                </template>
            </el-table-column>

            <el-table-column prop="transactionMethod" align="center" width="120" label="优惠券">
                <template slot-scope="{row}">
                    <div v-if="row.name">券名:{{ row.name }}</div>
                    <div v-if="row.satisfyTo" style="color:#409EFF"> 抵扣值:{{ row.satisfyTo }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="userName" align="center" width="140" label="下单用户">
                <template slot-scope="{row}">
                <div>用户ID：{{ row.userId }}</div>
                <div>用户名：{{ row.userName }}{{ row.userPhone }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="createdTime" align="center" width="180" label="下单时间">
                <template slot-scope="{row}">
                    <span v-if="row.createdTime"> {{ format(new Date(row.createdTime), 'yyyy-MM-dd hh:mm:ss') }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="truckUserName" align="center" width="140" label="货车司机">
                <template slot-scope="{row}">
                    <div v-if="row.truckUserId">用户ID：{{ row.truckUserId }}</div>
                    <div v-if="row.truckUserId">用户名：{{ row.truckUserName }}{{ row.truckUserPhone }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="orderGrabbingTime" align="center" width="180" label="接单时间">
                <template slot-scope="{row}">
                    <span v-if="row.orderGrabbingTime"> {{ format(new Date(row.orderGrabbingTime), 'yyyy-MM-dd hh:mm:ss')}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="pickingTime" align="center" width="200" label="揽货时间">
                <template slot-scope="{row}">
                    <span v-if="row.pickingTime"> {{ format(new Date(row.pickingTime), 'yyyy-MM-dd hh:mm:ss') }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="icon" align="center" width="280" fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain @click="Detail(scope.row.billId)">详情</el-button>
                    <el-button type="success" plain @click="Maps(scope.row.billId)">轨迹</el-button>
                    <template v-if="scope.row.billState == 6">
                        <el-popconfirm title="确定取消该订单吗？" @confirm="delHandle(scope.row.billId)">
                            <el-button type="danger" plain slot="reference">取消订单</el-button>
                        </el-popconfirm>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20, 50, 100]" :current-page="data.pageNo"
            :page-size="data.PageSize" :total="total">
        </el-pagination>
        <billdetail ref="getDetail" v-if="detailisible"></billdetail>
        <billMap ref="getMap" v-if="mapisible" />
    </div>
</template>

<script>
import billdetail from "./billdetail";
import billMap from "./billMapcopy";
import ty from "@/utils/ty";
import axios from 'axios';
export default {
    name: "CommonExplain",
    components: {
        billdetail, billMap
    },
    data() {
        return {
            total: 0,
            billStateList: [{
                billState: 0, billStateName: '失效'
            }, {
                billState: 1, billStateName: '待支付订金'
            },
            {
                billState: 2, billStateName: '待确定货车司机'
            },
            {
                billState: 3, billStateName: '待运'
            },
            {
                billState: 4, billStateName: '运输中'
            },
            {
                billState: 5, billStateName: '确定费用'
            },
            {
                billState: 6, billStateName: '待支付运费'
            },
            {
                billState: 7, billStateName: '运输完成'
            },
            {
                billState: 8, billStateName: '申请取消'
            },
            {
                billState: 9, billStateName: '同意取消'
            },
            {
                billState: 10, billStateName: '售后'
            }
            ],
            tableData: [],
            detailisible: false,
            mapisible: false,
            data: {
                address: "",
                billCode: "",
                billState: null,
                goodsName: "",
                pageNo: 1,
                pageSize: 10
            }
        }
    },
    created() {
        this.getgetBillPage();
    },
    methods: {
        exportSurfList() {
           var obj = {
               address:this.data.address||null,
               goodsName:this.data.goodsName||null,
               billCode:this.data.billCode||null,
               billState:this.data.billState||null,
           }
            axios({
                url: ty.apiurl + "/admin/bill/exportBill",
                method: 'post',
                data: obj,
                responseType: 'blob',
                headers: {
                    'Authorization': localStorage.getItem('token') || '',
                },
            })
                .then(res => {
                   
                    let blob = new Blob([res.data], { type: res.data.type });
                    let url = window.URL.createObjectURL(blob);
                    console.log(url);
                    let link = document.createElement("a");
                    link.style.display = "none";
                    link.href = url;
                    link.setAttribute("download","订单管理.xlsx");//文件名后缀记得添加，我写的zip
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url);//释放掉blob对象
                })
        },
        format(date, fmt) {
            var o = {
                "M+": date.getMonth() + 1,                 //月份 
                "d+": date.getDate(),                    //日 
                "h+": date.getHours(),                   //小时 
                "m+": date.getMinutes(),                 //分 
                "s+": date.getSeconds(),                 //秒 
                "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
                "S": date.getMilliseconds()             //毫秒 
            };
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
            }
            for (var k in o) {
                if (new RegExp("(" + k + ")").test(fmt)) {
                    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
                }
            }
            return fmt;
        },
        handleSizeChange(val) {
            this.data.pageSize = val
            this.data.pageNo = 1
            this.getgetBillPage()
        },
        handleCurrentChange(val) {
            this.data.pageNo = val
            this.getgetBillPage()
        },

        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.delBtlStatu = val.length == 0
        },
        delHandle(event) {
            var that = this
            that.$axios.post("/admin/bill/cancellationOrder", { billId: event }).then(res => {
                that.$message({
                    showClose: true,
                    message: '恭喜你，操作成功',
                    type: 'success',
                    onClose: () => {
                        that.getgetBillPage()
                    }
                });
            })
        },
        getgetBillPage() {
            this.$axios.post("/admin/bill/getBillPage", this.data).then(res => {
                this.tableData = res.data.records
                this.total = res.data.total
            })
        },
        Detail(billId) {
            this.detailisible = true;
            this.$nextTick(() => {
                this.$refs.getDetail.init(billId);
            })
        },
        Maps(billId) {
            this.mapisible = true;
            this.$nextTick(() => {
                this.$refs.getMap.init(billId);
            })
        }
    }
}
</script>

<style scoped>
.el-button {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    margin-right: 10px;
}
</style>
